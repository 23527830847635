<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { computed } from 'vue'
  import useBem from '~/composables/Bem/Bem'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import { useNavigationStore } from '~/store/Navigation'

  interface Props extends UseBemProps {
    facets?: Array<string>
    isActive: boolean
  }

  const props = defineProps<Props>()
  const navigationStore = useNavigationStore()
  const { bemAdd, bemFacets } = useBem('c-btn-navigation', props, {})

  const { isOpen } = storeToRefs(navigationStore)

  const rootClasses = computed(() => {
    return [
      bemFacets.value,
      bemAdd(props.isActive ? 'is-active' : ''),
      bemAdd(isOpen.value ? 'has-open-menu' : 'has-closed-menu'),
    ]
  })
</script>

<template>
  <button :class="rootClasses" class="c-btn-navigation btn">
    <span class="c-btn-navigation__root">
      <span v-for="n in 3" :key="n" class="c-btn-navigation__bar" />
    </span>
  </button>
</template>

<style lang="scss" scoped>
  @use 'sass:map';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/transition' as trs;
  @use '/components/Ui/UiIcon/UiIcon' as UiIcon;

  $size: map.get(UiIcon.$icon-sizes, 'xx-large');

  $bar-height: 3px;
  $icon-width: 44px;
  $icon-height: 30px;

  .c-btn-navigation {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    border: 1px solid transparent;
    background-clip: padding-box;

    overflow: hidden;
    text-align: center;

    &.c-btn-navigation--visual-theme-negative {
      color: col.$label-text-negative;
      background-color: col.$label-bg-negative;

      @include utils.has-focus {
        &::after {
          background-color: col.$variant-red-dark;
        }
      }
    }

    &.c-btn-navigation--visual-theme-positive {
      color: col.$label-text-positive;
      background-color: col.$label-bg-positive;

      @include utils.has-focus {
        &::after {
          background-color: col.$brand-cyan;
        }
      }
    }

    &::after {
      @include utils.overlay;
      content: '';
      pointer-events: none;
      z-index: 1;
      background-color: transparent;
      transition: background-color trs.$default;
    }
  }

  .c-btn-navigation__root {
    z-index: 2;
    position: relative;
    display: block;
    width: $icon-width;
    height: $icon-height;
  }

  .c-btn-navigation__bar {
    @include trs.common-props;
    position: absolute;
    left: 0;
    top: 50%;

    display: block;
    width: 100%;
    height: $bar-height;

    background-color: currentColor;
    opacity: 1;

    &:nth-child(1) {
      transform: translateY($icon-height * -0.5);
    }

    &:nth-child(2) {
      transform: translateY($bar-height * -0.5);
    }

    &:nth-child(3) {
      transform: translateY(($icon-height * 0.5) - $bar-height);
    }
  }

  .c-btn-navigation--is-active {
    .c-btn-navigation__bar {
      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
</style>
